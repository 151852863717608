













import Card from '@/components/layout/Card.vue'
import Badge from '@/components/status/Badge.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { GetFileModel } from 'tradingmate-modules/src/models/api/files'

@Component({
  components: {
    Badge,
    Card
  }
})
export default class MemberImageCard extends Vue {
  @Prop({ required: true })
  private readonly file!: GetFileModel
}
