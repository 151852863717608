











import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class ContactDetail extends Vue {
  @Prop()
  private readonly icon!: string;

  @Prop()
  private readonly imgSrc!: string;

  @Prop()
  private readonly link!: string;
}

