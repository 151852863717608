










import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Copyable extends Vue {
  @Prop({ required: true })
  private readonly link!: string;

  @Prop({ required: true })
  private readonly label!: string;

  private copied = false;

  copy () {
    let success = false

    if (!navigator.clipboard) {
      const elem = this.$refs.input as HTMLInputElement

      elem.focus()
      elem.select()

      try {
        success = document.execCommand('copy')
      } catch (ex) {
      }

      if (!success) prompt('Copy the text below:', this.link)
    } else {
      navigator.clipboard.writeText(this.link).then(() => {
        success = true
      }).catch(() => {
        prompt('Copy the text below:', this.link)
      })
    }

    this.copied = true
  }
}
