
















import { Vue, Component, Prop } from 'vue-property-decorator'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination])

@Component({
  components: {
    Swiper,
    SwiperSlide
  }
})

export default class Gallery extends Vue {
  @Prop({ required: true })
  private readonly images!: string[];

  @Prop({ default: null })
  private readonly altText!: string | null

  onSwiper (): void {
    //
  }

  onSlideChange (): void {
    //
  }
}

