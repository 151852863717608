













import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class FcebookShare extends Vue {
  private readonly url: string = this.$route.fullPath

  get fullUrl (): string {
    return `https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Ftradingmate.com.au/${this.url}&layout=button&size=large&width=77&height=28&appId`
  }
}
